<template>
  <div class="mb-7">
    <h2>{{ $t("addresses.autoDiscovery.title") }}</h2>
    <div class="d-flex">
      <vue-google-autocomplete
        id="map"
        classname="service-discovery"
        ref="address"
        :placeholder="$t('addresses.autoDiscovery.placeholder')"
        v-on:placechanged="getAddressData"
        :enable-geolocation="true"
        country="it"
        v-if="googleEnabled"
      >
      </vue-google-autocomplete>
      <!-- <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            title="Geolocalizzami"
            type="submit"
            class="login-button"
            large
            depressed
            icon
            @click="geolocate"
            v-bind="attrs"
            v-on="on"
            ><v-icon>mdi-crosshairs-gps</v-icon></v-btn
          >
        </template>
        {{ $t("addresses.autoDiscovery.buttonTooltip") }}
      </v-tooltip> -->
    </div>
    <v-card v-if="results" class="results mt-2">
      <v-list>
        <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.deliveryService") }}
          </h3>
        </v-subheader>
        <v-list-item>
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon logo-home"
              :src="
                $t('navbar.service.3.icon', {
                  color: this.dataValidated.active == 1 ? 'color' : 'grey'
                })
              "
              alt="Logo Casa"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="auto-discovery-title"
              v-if="this.dataValidated.active == 1"
            >
              {{ $t("addresses.autoDiscovery.addressOk") }}
            </v-list-item-title>
            <v-list-item-title class="auto-discovery-title" v-else>
              {{ $t("addresses.autoDiscovery.addressKo") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="dataValidated.active == 1"
              color="primary"
              depressed
              @click="handleAddressCreation(dataValidated)"
            >
              <v-icon class="d-inline d-sm-none">mdi-map-marker-outline</v-icon>
              <span class="d-none d-sm-inline">
                {{ $t("addresses.autoDiscovery.addAddressButton") }}
              </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.driveService") }}
          </h3>
        </v-subheader>
        <v-list-item v-if="warehouses.addresses.length == 0">
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon logo-home"
              :src="
                $t('navbar.service.1.icon', {
                  color: 'grey'
                })
              "
              alt="Logo Casa"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{
              $t("addresses.autoDiscovery.noStoresFound")
            }}</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          two-line
          v-for="warehouse in warehouses.addresses"
          :key="warehouse.addressId"
        >
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon"
              :src="$t('navbar.service.1.icon', { color: 'color' })"
              alt="Logo drive"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="auto-discovery-title">
              {{
                $t(
                  "navbar.address." + warehouse.addressTypeId + ".format",
                  warehouse
                )
              }}
            </v-list-item-title>
            <v-list-item-subtitle class="auto-discovery-subtitle">
              {{
                $t(
                  "navbar.address." + warehouse.addressTypeId + ".list",
                  warehouse
                )
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action
            ><v-btn
              color="primary"
              depressed
              @click="
                handleWarehouseClick(warehouse.addressType, warehouse.addressId)
              "
            >
              <v-icon class="d-inline d-sm-none">mdi-map-marker-outline</v-icon>
              <span class="d-none d-sm-inline">
                {{ $t("addresses.autoDiscovery.start") }}
              </span>
            </v-btn></v-list-item-action
          >
        </v-list-item>

        <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.lockerService") }}
          </h3>
        </v-subheader>
        <v-list-item v-if="lockers.addresses.length == 0">
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon logo-home"
              :src="
                $t('navbar.service.10002.icon', {
                  color: 'grey'
                })
              "
              alt="Logo Casa"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t("addresses.autoDiscovery.noLockersFound") }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          v-for="locker in lockers.addresses"
          :key="locker.addressId"
        >
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon locker-logo"
              :src="$t('navbar.service.10002.icon', { color: 'color' })"
              alt="Logo locker"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="auto-discovery-title">
              {{
                $t("navbar.address." + locker.addressTypeId + ".format", locker)
              }}
            </v-list-item-title>
            <v-list-item-subtitle class="auto-discovery-subtitle">
              {{
                $t("navbar.address." + locker.addressTypeId + ".list", locker)
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action
            ><v-btn
              color="primary"
              depressed
              @click="
                handleWarehouseClick(locker.addressType, locker.addressId)
              "
            >
              <v-icon class="d-inline d-sm-none">mdi-map-marker-outline</v-icon>
              <span class="d-none d-sm-inline">
                {{ $t("addresses.autoDiscovery.start") }}
              </span>
            </v-btn></v-list-item-action
          >
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.service-discovery {
  background-color: white;
  border: 1px solid $border-color;
  line-height: 20px;
  padding: 8px;
  width: 100%;
  border-color: rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}
.service-icon-wrapper {
  width: 50px !important;
  margin-right: 10px !important;
}
.service-icon {
  height: 24px;
  margin-top: 6px;
  &.logo-home {
    height: 29px;
  }
  &.locker-logo {
    height: 31px;
  }
}
.add-address-icon {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
.auto-discovery-title,
.auto-discovery-subtitle {
  white-space: normal !important;
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AddressService from "~/service/addressService";
import gmapsInit from "./gmaps";
import login from "~/mixins/login";
import { mapActions } from "vuex";
import GeoService from "~/service/geoService";
import includes from "lodash/includes";
var google;

export default {
  name: "ServiceAutodiscory",
  components: { VueGoogleAutocomplete },
  mixins: [login],
  data() {
    return {
      warehouses: [],
      lockers: [],
      dataValidated: {},
      deliveryServiceAvailable: null,
      results: false,
      googleEnabled: false
    };
  },
  computed: {
    address() {
      return this.$t(
        "navbar.address." + this.dataValidated.addressTypeId + ".format",
        this.dataValidated
      );
    }
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async getAddressData(addressData, placeResult) {
      //check @home
      try {
        this.dataValidated = await AddressService.isValidAddress({
          countryId: 1,
          city: addressData.locality,
          postalcode: addressData.postal_code,
          address1: addressData.route,
          addressNumber: addressData.street_number,
          gpsLongitude: addressData.longitude,
          gpsLatitude: addressData.latitude,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          province: this.getProvince(placeResult)
        });
        this.deliveryServiceAvailable = true;
      } catch (err) {
        this.deliveryServiceAvailable = false;
      }
      //lookup drive
      try {
        this.warehouses = await AddressService.getAddressByDeliveryService(
          1,
          addressData.latitude,
          addressData.longitude,
          global.config.proximityDefaultDistance
        );
      } catch (err) {
        console.log(err);
      }
      try {
        //lookup locker
        this.lockers = await AddressService.getAddressByDeliveryService(
          10002,
          addressData.latitude,
          addressData.longitude,
          global.config.proximityDefaultDistance
        );
      } catch (err) {
        console.log(err);
      }
      this.results = true;
    },

    getProvince(placeResult) {
      let area = placeResult.address_components.find(p => {
        return includes(p.types, "administrative_area_level_2");
      });
      return area["short_name"];
    },

    async handleAddressCreation(address) {
      if (await this.needLogin("service-autodiscovery")) {
        this.$router.push({
          name: "EditAddresses",
          params: {
            newAddress: true,
            discoveredAddress: address
          }
        });
      }
      this.$emit("submit", false);
    },
    async handleWarehouseClick(addressType, addressId) {
      if (await this.needLogin("service-autodiscovery")) {
        await this.setAddress({
          addressType: addressType,
          addressId: addressId
        });
        if (await this.needTimeslot()) {
          this.$router.push({ name: "Home" });
        }
      }
    },
    geolocate() {
      let _this = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                var address = GeoService.decodeGoogleAddress(results[0]);
                address.latitude = address.latitude();
                address.longitude = address.longitude();
                var addressFormat = GeoService.composeAddress(address);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                _this.getAddressData(address);
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    }
  },
  async mounted() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (error) {
      throw new Error("Error Loading GMAPS");
    }
  }
};
</script>
